body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0 !important;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background-color: #333;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensures the header stays on top */
  background-color: #FFF;
}

@media (max-width: 768px) {x
  header {
      padding: 10px 20px;
  }
}

/* Left side: Title and subtitle */
.title-container {
  display: flex;
  flex-direction: column;
}

.header-text {
  color: rgb(29 52 94);
  margin: 0;

}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 14px;
  font-weight: normal;
}

nav {
  display: block;
}

/* Hide the nav on smaller screens */
@media (max-width: 768px) {
  nav {
      display: none;
  }
}

/* Right side: Navigation */
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
}

header a {
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}

.donate-button {
  background-color: #007dbd; /* Blue background */
  color: white; /* White text color */
  border: none; /* Remove default border */
  border-radius: 6px; /* Rounded corners */
  padding: 8px 16px; /* Vertical and horizontal padding */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.donate-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Style for the main content */
main {
  margin: 0;
  padding: 0;
}

/* Container for the two images */
.image-container {
  display: flex;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

/* Style for the left image */
.left-image {
  width: 75%;
  height: auto; /* Ensure image scales correctly */
}

/* Style for the right image */
.right-image {
  width: 25%;
  height: auto; /* Ensure image scales correctly */
}

/* Remove any spacing between the images */
img {
  display: block;
  margin: 0;
  padding: 0;
}

/* Style for section 1 */
#section-1 {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  background-color: #fff;
}

/* Style for the left column */
#section-1 .left-column {
  flex: 1; /* Takes up 50% of the width */
  padding: 20px 40px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #section-1 .left-column {
      padding-left: 0px;
  }
}

#section-1 .title-container {
  text-align: center;
}

#section-1 .left-column h2 {
  margin-top: 0;
  font-size: 40px;
  color: rgb(29 52 94);
  font-weight: bold;
  line-height: 1.2;
}

#section-1 .left-column p {
  font-size: 18px;
  color: #333;
  line-height: 1.5;

}

/* Style for the right column */
#section-1 .right-column {
  flex: 1; /* Takes up 50% of the width */
  padding: 10px;
}

#section-1 .video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

#section-1 .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

#section-1 .article-box {
  display: block;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease;
}

#section-1 .article-box:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

#section-1 .title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

#section-1 .subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 15px;
}

#section-1 .meta {
  font-size: 0.9rem;
  color: #999;
}

#section-1 .meta .author {
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #section-1 .left-column,
  #section-1 .right-column {
      flex: 100%; /* Each column takes up the full width */
      max-width: 100%; /* Ensures columns don’t exceed container width */
  }
}

/* Style for the section */
#endorsements-section {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  flex-wrap: wrap; /* Allows columns to wrap on small screens */
  background-color: #003971;
}

/* Style for the left column */
#endorsements-section .left-column {
  flex: 1;
  padding: 10px;
  position: relative; /* For positioning carousel controls */
  text-align: center;
}

#endorsements-section .carousel-title {
  font-size: 24px;
  color: #fff
}

#endorsements-section .endorsements-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

#endorsements-section .endorsements-item h3{
  color: white;
  margin: 10px 0;
}

#endorsements-section .endorsements-item p{
  color: white;
  padding-top: 5px;
  padding-right: 6px;
  margin: 6px 0;
}


/* Style for the right column */
#endorsements-section .right-column {
  flex: 1;
  padding: 10px;
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center content horizontally */
}

#countdown {
  text-align: center;
}

#countdown .countdown-title {
  font-size: 24px;
  color: #fff
}

#timer {
  display: flex;
  justify-content: space-around;
  font-size: 1.5em;
  margin-top: 10px;
}

#timer .timer-item-container{
  margin: 0 10px;
}

#timer div {
  padding: 10px 10px 0 10px;
  color: #fff;
  font-size: 36px;
}

#timer h2 {
  font-size: 20px;
  color: #fff;
  margin: 0;
}

#about-section {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 40px;
  box-sizing: border-box;
  flex-wrap: wrap;
  background-color: #fff;
}

#about-section .text-container {
  width: 60%
}

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
  #about-section .text-container {
      width: 100%
  }
}

#about-section .title-container {
  text-align: center;
}

#about-section h2 {
  margin: 0;
  font-size: 40px;
  color: rgb(29 52 94);
  font-weight: bold;
  line-height: 1.2;
}

#about-section p {
  margin: 30px 10px;
  font-size: 18px;
  line-height: 1.2;
}

/* Style for the Facebook icon button */
#about-section .icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: white; /* Icon color */
  border: none;
  border-radius: 50%; /* Rounded button */
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s ease;
}

#about-section .button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px
}

/* Style for the form container with background image */
#contact-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url('https://storage.googleapis.com/mary-west-linn-static-content/static/mount-hood-at-sunset-over-oregon-landscape-panorama-jpldesigns.jpg'); /* Path to your background image */
  background-size: cover; /* Cover the entire form area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating the image */
  color: white;
  padding-left: 40px;
}

#contact-section .contact-form {
  width: 35%;
  padding: 20px;
  color: #fff; /* Text color to contrast with background */
  display: flex;
  flex-wrap: wrap;
}

#contact-section h2 {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  color: rgb(29 52 94);
}


#contact-section .form-row {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping of items on smaller screens */
  gap: 20px; /* Spacing between columns */
  width: 100%;
}

#contact-section .form-group {
  flex: 1;
  min-width: calc(50% - 20px); /* 50% width minus gap */
}

#contact-section .form-group label {
  display: block;
  margin-bottom: 5px;
}

#contact-section .form-group input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background for inputs */
}

#last-name-container, #phone-container {
  padding-left: 10px;
}

#contact-section .button-container {
  width: 100%;
}

#contact-section button {
  width: 100px;
  padding: 8px;
  background-color: #007dbd;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

#contact-section button:hover {
  background-color: #0056b3;
}

/* Responsive styling for smaller devices */
@media (max-width: 1168px) {
  #contact-section  .contact-form {
      min-width: 50%; /* Full width on smaller screens */
  }
  #last-name-container, #phone-container {
      padding-left: 0;
  }
}

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
  #contact-section .form-row {
      gap: 0; /* Spacing between columns */
  }
  #contact-section  .contact-form {
      min-width: 80%; /* Full width on smaller screens */
  }
  #contact-section  .form-group {
      min-width: 100%; /* Full width on smaller screens */
  }
  #last-name-container, #phone-container {
      padding-left: 0;
  }
}