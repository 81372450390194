* {
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

body {
    font-family: Arial, sans-serif; /* Set font style for the page */
    background-color: #f4f4f4; /* Light background color */
    margin: 0; /* Remove default margin */
    padding: 20px; /* Add padding around the body */
}

.container {
    max-width: 600px; /* Limit the width of the container */
    margin: auto; /* Center the container */
    background: #fff; /* White background for the container */
    padding: 20px; /* Padding inside the container */
    border-radius: 5px; /* Slightly rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow for a lifted effect */
}

.header-text {
    text-align: start;
}

.text-box-container {
    margin-bottom: 20px; /* Space below the text box container */
}

.text-box {
    width: 100%; /* Full width */
    padding: 10px; /* Padding inside the text box */
    margin-bottom: 10px; /* Space below each text box */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Slightly rounded corners */
    resize: none; /* Disable resizing */
}

.submit-button {
    width: 100%; /* Full width */
    padding: 10px; /* Padding inside the button */
    background-color: #28a745; /* Green background color */
    color: white; /* White text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Slightly rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Increase font size */
}

.submit-button:hover {
    background-color: #218838; /* Darker green on hover */
}

.svg-button {
    width: 50px; /* Button width */
    height: 30px; /* Button height */
    background-color: blue; /* blue background */
    border: none; /* No border */
    border-radius: 4px; /* Rounded shape */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex; /* Center SVG in button */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    transition: background-color 0.3s; /* Transition for hover effect */
}

.svg-button:hover {
    background-color: navy; /* Darker blue on hover */
}

.svg-icon {
    fill: white; /* White color for the plus icon */
    width: 24px; /* Icon width */
    height: 24px; /* Icon height */
}

.endorsement-container {
    display: flex;
    gap: 10px; /* Optional: adds space between the fields */
}
  
.endorsement-container input {
    width: 50%;
    padding: 8px;
    box-sizing: border-box; /* Ensures padding is included in the width */
    margin-bottom: 10px; /* Space below each text box */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Slightly rounded corners */
    resize: none; /* Disable resizing */
}